﻿.emu-scr-master {

	/* this class is only used in custom html reports and it's height 
	 * is calculated in javascript @ scope.Master.Panel.AdjustHeight.Action... 
	 */
	&__main { 
		height: 100%;
		overflow-y: auto;  
	}

	&__header {
		width: 100%;
		padding-top: 15px;
		overflow: hidden;

		&:empty {
			display: none;
		}

		&__title {
			margin: 0;
			text-align: left;
			font-size: 14px;
			font-weight: bold;
			color: #000000;
		}
	}

	&__footer {
		float: left;
		width: calc(100% - 30px);
		padding: 15px 0;
		position: absolute;
		bottom: 0px;
		overflow: hidden;

		&:empty {
			display: none;
		}
	}

	&__row {
		position: relative;
		width: calc(100% - 4px); /* width: 100%; */
		float: left;
		margin: 15px 0 0 0;
	}
}

// Overrides
// TODO (DT): Check if this needs to be here or requires move

.emu-scr-cmp-wrapper--side .emu-scr-master__footer {
	width: 100%;
	padding-bottom: 0;
	bottom: 21px; /* 20px container padding + 1px border spacing */
}
