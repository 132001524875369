﻿$emu-scr-toolbar-border-color: #e1e1e1;
$emu-scr-toolbar-background-color: #f7f7f7;
$emu-scr-toolbar-arrow-color: #aaa79d;
$emu-scr-toolbar-members-color: #a7abb0;
$emu-scr-toolbar-separator-color: #ddd;
$emu-scr-toolbar-list-item-hover-shadow-color: #252a69;
$emu-scr-toolbar-list-item-hover-color: #eaf0f9;
$emu-scr-toolbar-list-item-hover-arrow-color: #5e5843;

.emu-scr-toolbar {
	$self: &;
	position: relative;
	float: left;
	width: 100%;
	min-height: 50px;
	border: 1px solid $emu-scr-toolbar-border-color;
	border-left: none;
	border-right: none;
	background: $emu-scr-toolbar-background-color;

	&__list {
		display: block;
		float: left;
		margin: 0;
		padding: 0 10px;
		list-style: none;
	}

	&__list-item {
		float: left;
		display: block;
		padding: 8px 5px;
	}

	&__item {
		position: relative;
		z-index: 50;
		float: left;
		display: block;
		cursor: pointer;

		&:hover {
			background-color: $emu-scr-toolbar-list-item-hover-color;
			box-shadow: 0 0px 2px $emu-scr-toolbar-list-item-hover-shadow-color;

			.emu-scr-toolbar__arrow {
				border-top-color: $emu-scr-toolbar-list-item-hover-arrow-color;
			}

			.emu-scr-toolbar__members {
				opacity: 1;
				visibility: visible;
			}
		}

		&--disabled {
			opacity: 0.5;
			cursor: not-allowed;

			.emu-scr-toolbar__members {
				display: none;
			}

			&:hover {
				background-color: $emu-scr-toolbar-background-color;
				box-shadow: none;
			}
		}
	}

	&__icon {
		display: block;
		float: left;
		width: 32px;
		height: 32px;
	}

	&__label {
		display: block;
		float: left;
		padding-left: 5px;
		line-height: 24px;
		font-size: 12px;
	}

	&__separator {
		display: block;
		float: left;
		height: 32px;
		width: 2px;
		border: 1px solid #fff;
		border-bottom: none;
		border-top: none;
		border-left-color: $emu-scr-toolbar-separator-color;

		&--arrow {
			position: relative;
			height: 24px;
			margin-left: 5px;
			margin-top: 4px;
		}
	}

	&__arrow {
		display: block;
		float: left;
		width: 0;
		height: 0;
		margin: 15px 2px;
		margin-bottom: 0;
		border: solid 4px transparent;
		border-top: solid 4px $emu-scr-toolbar-arrow-color;
	}

	&__members {
		position: absolute;
		left: -1px;
		top: 32px;
		z-index: 300;
		padding: 2px;
		border: solid 1px $emu-scr-toolbar-members-color;
		box-shadow: 2px 2px 3px rgba(0, 0, 0, .25);
		background: #fff;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.2s;

		#{$self}__list {
			padding: 0;
		}

		#{$self}__list-item {
			width: 100%;
			min-width: 180px;
			padding: 2px;

			&:hover {
				background-color: $emu-scr-toolbar-list-item-hover-color;
			}
		}

		#{$self}__icon {
			width: 20px;
			height: 20px;
		}

		#{$self}__label {
			line-height: 20px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			width: calc(100% - 25px); /* 25px: 20px -> icon width, 5px -> label's padding-left */
		}
	}

	&--detail {
		float: left;
		width: 100%;
		min-height: 42px;
		height: auto;
		border: 1px solid $emu-scr-toolbar-border-color;

		#{$self}__list {
			padding: 0 3px;
		}

		#{$self}__list-item {
			padding: 5px 2px;
			cursor: pointer;
		}

		#{$self}__item {
			padding: 3px;
		}

		#{$self}__icon {
			width: 24px;
			height: 24px;
		}

		#{$self}__separator {
			height: 24px;
			margin-top: 3px;
		}

		#{$self}__separator--arrow {
			height: 24px;
			margin-top: 0;
		}

		#{$self}__arrow {
			margin-top: 11px;
		}

		#{$self}__members {
			top: 30px;
		}
	}
}
