﻿$emu-scr-sidebar-color-grey-dark: #535353;
$emu-scr-sidebar-color-grey-light: #eaeaea;

.emu-scr-sidebar {

	&__wrapper {
		padding: 0;
		height: 100%;
		width: 100%;
		font-size: 12px;
	}

	&__header {
		padding: 3px 10px;
		white-space: nowrap;
		cursor: default;
		font-size: 13px;
		color: #fff;
		opacity: 0.8;
	}

	&__header-icon {
		padding-right: 5px;
		color: #fff;
	}

	&__content {
		width: calc(100% - 36px);
		height: 100%;
		position: relative;
		float: left;

		&--left {
			float: left;
		}

		&--right {
			float: right;
		}
	}

	&__button-handlers-panel {
		position: relative;
		z-index: 10;
		float: left;
		width: 36px;
		height: 100%;
		padding-top: 45px;
		background-color: $emu-scr-sidebar-color-grey-light;

		&--with-filter-icon {
			padding-top: 45px;
		}

		&--right {
			float: right;
		}

		&--left {
			float: left;
		}
	}

	&__button-group {
		background-color: $emu-scr-sidebar-color-grey-light;
	}

	&__button {
		margin: 0 !important;
		padding-left: 7px;
		padding-right: 6px;
		border: none;
		background-color: inherit;
		color: $emu-scr-sidebar-color-grey-dark;
		outline: none;

		&--active {
			color: $base-branding-color-2 !important;
			background-color: $emu-scr-sidebar-color-grey-light !important;
		}

		&:active, &:focus {
			outline: none !important;
			box-shadow: none !important;
		}

		&:hover {
			color: $base-branding-color-2;
		}
	}

	&__button-icon {
		font-size: 18px;
	}

	&__bottom-panel {
		position: relative;
		z-index: 10;
		width: 100%;
		height: 36px;
		background-color: $emu-scr-sidebar-color-grey-light;
		top: 0px;
		text-align: center;
		line-height: 36px; /*same as div height for vertical align*/
		cursor: pointer;
	}

	&__tooltip {
		background: $emu-scr-sidebar-color-grey-dark !important;
		color: #fff !important;

		.k-callout-e {
			border-left-color: $emu-scr-sidebar-color-grey-dark !important;
		}

		.k-callout-w {
			border-right-color: $emu-scr-sidebar-color-grey-dark !important;
		}
	}
}
