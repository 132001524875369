﻿/**
 * Helper class to truncate and add ellipsis to a string too long for it to fit
 * on a single line.
 * 1. Prevent content from wrapping, forcing it on a single line.
 * 2. Add ellipsis at the end of the line.
 */
@mixin ellipsis {
	white-space: nowrap; /* 1 */
	text-overflow: ellipsis; /* 2 */
	overflow: hidden;
}
