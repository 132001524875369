﻿$base-branding-color-2: #3591d2;
/* color-branding-2-hover: #e6f3fe; */

/* sidebar-background-color: #eaeaea;*/
/* master-detail-border-color: #dadada */

/* UI */
$theme-color-highlight: #e6f3fe; /* highlight in records mostly used in tabular designs */
$theme-color-strip: #f9f9f9; /* data-table strip rows */ 

/* States */
$state-info-color: #3591d2;
$state-warning-color: #f2e228;
$state-error-color: #dc3030;
$state-success-color: #16ab1d;
$state-default-color: #aaa;

/* shades of grey */
$black: #000000;
$dark-grey: #525252;
$grey: #808080;
$light-grey: #d3d3d3;
$almost-white: #f2f2f2;
$white: #ffffff;

/* danaos_sky theme */
$danaos_sky-color-main: #073464;
$danaos_sky-color-active: #1A73F0;

.danaos_sky .emu-scr-theme-color {
	color: $danaos_sky-color-main;
}

.danaos_sky .emu-scr-theme-background {
	background-color: $danaos_sky-color-main;
}

.danaos_sky .emu-scr-theme-border {
	border-color: $danaos_sky-color-main;
}

.danaos_sky .emu-scr-theme-color--active {
	color: $danaos_sky-color-active;
}

.danaos_sky .emu-scr-theme-background--active {
	background-color: $danaos_sky-color-active;
}

.danaos_sky .emu-scr-theme-border--active {
	border-color: $danaos_sky-color-active;
}

/* danaos_ice theme */
$danaos_ice-color-main: #CBD6E2;
$danaos_ice-color-active: #1A73F0;

.danaos_ice .emu-scr-theme-color {
	color: $danaos_ice-color-main;
}

.danaos_ice .emu-scr-theme-background {
	background-color: $danaos_ice-color-main;
}

.danaos_ice .emu-scr-theme-border {
	border-color: $danaos_ice-color-main;
}

.danaos_ice .emu-scr-theme-color--active {
	color: $danaos_ice-color-active;
}

.danaos_ice .emu-scr-theme-background--active {
	background-color: $danaos_ice-color-active;
}

.danaos_ice .emu-scr-theme-border--active {
	border-color: $danaos_ice-color-active;
}

/* danaos_red theme */
$danaos_red-color-main: rgb(194, 46, 75);
$danaos_red-color-active: #d1d122;

.danaos_red .emu-scr-theme-color {
	color: $danaos_red-color-main;
}

.danaos_red .emu-scr-theme-background {
	background-color: $danaos_red-color-main;
}

.danaos_red .emu-scr-theme-border {
	border-color: $danaos_red-color-main;
}

.danaos_red .emu-scr-theme-color--active {
	color: $danaos_red-color-active;
}

.danaos_red .emu-scr-theme-background--active {
	background-color: $danaos_red-color-active;
}

.danaos_red .emu-scr-theme-border--active {
	border-color: $danaos_red-color-active;
}

/* danaos_dawn theme */
$danaos_dawn-color-main: #202631;
$danaos_dawn-color-active: #1A73F0;

.danaos_dawn .emu-scr-theme-color {
	color: $danaos_dawn-color-main;
}

.danaos_dawn .emu-scr-theme-background {
	background-color: $danaos_dawn-color-main;
}

.danaos_dawn .emu-scr-theme-border {
	border-color: $danaos_dawn-color-main;
}

.danaos_dawn .emu-scr-theme-color--active {
	color: $danaos_dawn-color-active;
}

.danaos_dawn .emu-scr-theme-background--active {
	background-color: $danaos_dawn-color-active;
}

.danaos_dawn .emu-scr-theme-border--active {
	border-color: $danaos_dawn-color-active;
}

/* danaos_gray theme */
$danaos_gray-color-main: #485563;
$danaos_gray-color-active: #d1d122;

.danaos_gray .emu-scr-theme-color {
	color: $danaos_gray-color-main;
}

.danaos_gray .emu-scr-theme-background {
	background-color: $danaos_gray-color-main;
}

.danaos_gray .emu-scr-theme-border {
	border-color: $danaos_gray-color-main;
}

.danaos_gray .emu-scr-theme-color--active {
	color: $danaos_gray-color-active;
}

.danaos_gray .emu-scr-theme-background--active {
	background-color: $danaos_gray-color-active;
}

.danaos_gray .emu-scr-theme-border--active {
	border-color: $danaos_gray-color-active;
}

/* danaos_green theme */
$danaos_green-color-main: #2D5358;
$danaos_green-color-active: #ECA270;

.danaos_green .emu-scr-theme-color {
	color: $danaos_green-color-main;
}

.danaos_green .emu-scr-theme-background {
	background-color: $danaos_green-color-main;
}

.danaos_green .emu-scr-theme-border {
	border-color: $danaos_green-color-main;
}

.danaos_green .emu-scr-theme-color--active {
	color: $danaos_green-color-active;
}

.danaos_green .emu-scr-theme-background--active {
	background-color: $danaos_green-color-active;
}

.danaos_green .emu-scr-theme-border--active {
	border-color: $danaos_green-color-active;
}

/* danaos_term theme */
$danaos_term-color-main: #000000;
$danaos_term-color-active: #00ff00;

.danaos_term .emu-scr-theme-color {
	color: $danaos_term-color-main;
}

.danaos_term .emu-scr-theme-background {
	background-color: $danaos_term-color-main;
}

.danaos_term .emu-scr-theme-border {
	border-color: $danaos_term-color-main;
}

.danaos_term .emu-scr-theme-color--active {
	color: $danaos_term-color-active;
}

.danaos_term .emu-scr-theme-background--active {
	background-color: $danaos_term-color-active;
}

.danaos_term .emu-scr-theme-border--active {
	border-color: $danaos_term-color-active;
}

/* danaos_purple theme */
$danaos_purple-color-main: #7d5bbe;
$danaos_purple-color-active: #FFDA00;

.danaos_purple .emu-scr-theme-color {
	color: $danaos_purple-color-main;
}

.danaos_purple .emu-scr-theme-background {
	background-color: $danaos_purple-color-main;
}

.danaos_purple .emu-scr-theme-border {
	border-color: $danaos_purple-color-main;
}

.danaos_purple .emu-scr-theme-color--active {
	color: $danaos_purple-color-active;
}

.danaos_purple .emu-scr-theme-background--active {
	background-color: $danaos_purple-color-active;
}

.danaos_purple .emu-scr-theme-border--active {
	border-color: $danaos_purple-color-active;
}