﻿// icon themes
$icon__default: #aaa;
$icon__locked: orange;
$icon__messages: #00BCD4;
$icon__attachments: #73b3df;
$icon__disabled: #ccc;

.emu-scr-actions-panel {
	position: absolute;
	top: 0;
	right: -45px;

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__list-item {
		position: relative;
	}

	&__item {
		display: inline-block;
		width: 28px;
		height: 28px;
		margin: 5px;
		border: 1px solid #eee;
		border-radius: 4px;
		padding: 5px;
		color: #808080;
		font-size: 16px;
		cursor: pointer;

		&:first-child {
			margin-top: 0;
		}
	}

	&__attachments:hover, &__messages:hover, &__locked:hover, &__details:hover {
		color: $icon__default;
	}

	&__details--disabled, &__details--disabled:hover {
		color: $icon__disabled;
		cursor: default;
	}

	&__messages--active, &__messages--active:hover {
		color: $icon__messages;
		cursor: pointer;
	}

	&__locked--active, &__locked--active:hover {
		color: $icon__locked;
		cursor: pointer;
	}

	&__attachments--active, &__attachments--active:hover {
		color: $icon__attachments;
		cursor: pointer;
	}

	&__count {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 20;
		display: inline-block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		font-size: 10px;
		font-weight: 600;
		line-height: 15px;
		text-align: center;
		color: #fff;
		background: crimson;
	}
}

.emu-scr-master__row--focus .emu-scr-actions-panel {
	right: -49px;
}
