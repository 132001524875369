﻿.emu-scr-cmp-wrapper--tabular { }

.emu-scr-cmp-wrapper--tabular .emu-scr-cmp-wrapper__row {
	width: 100%;
	margin: 0;
	padding: 0;
	padding-top: 15px;
}

.emu-scr-cmp-wrapper--tabular .emu-scr-data-table__master { }

.emu-scr-cmp-wrapper--tabular .emu-scr-data-table__detail { }

.emu-scr-cmp-wrapper--tabular .emu-scr-data-table__main .cmp-checkbox .cmp__details {
	display: block;
	float: none;
	width: 16px !important;
	margin: 0 auto;
}

.emu-scr-cmp-wrapper--tabular .emu-scr-data-table__main .cmp-checkbox .cmp-checkbox__checkbox {
	top: 0;
}

/*#region Master Detail Tabular */

.emu-scr-cmp-wrapper--master-detail-tabular .emu-scr-data-table__detail {
	position: absolute;
	left: 0;
	top: 43px; /* TODO (DT): Make this somehow dynamic */
	z-index: 10;
	border: none;
	padding: 15px 0 0 0;
}

.emu-scr-cmp-wrapper--master-detail-tabular .emu-scr-data-table__row > .emu-scr-data-table__item:nth-last-child(2) { border-right: none; }

.emu-scr-cmp-wrapper--master-detail-tabular .emu-scr-data-table__row > .emu-scr-data-table__detail .emu-scr-data-table__item:nth-last-child(2) { border-right: 1px solid #ddd; } // default bootstrap styling for nested detail table

/*#endregion */

/*#region Master Tabular */



/*#endregion */

/*#region Detail Tabular */

.emu-scr-cmp-wrapper--tabular .emu-scr-detail__footer {
	position: static;
	float: left;
	width: 100%;
	padding: 15px 0;
	background-color: transparent;
}

/*#endregion */
