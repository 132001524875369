﻿.emu-scr-root {
	height: 100%;
	clear: both;
}

.emu-scr-screen-wall {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
}

/*#region scrollbar */
.emu-scr-master::-webkit-scrollbar-thumb, .emu-scr-master div::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: rgba(0,0,0,.5);
	box-shadow: 0 0 1px rgba(255,255,255,.5);
	margin-right: 3px;
}

.emu-scr-master::-webkit-scrollbar, .emu-scr-master div::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 6px;
	height: 8px;
}

/*#endregion */

/*#region splitter */

.emu-scr-splitter .k-splitbar {
	background-color: #ddd !important;
}

.emu-scr-splitter .k-splitbar:hover {
	background-color: #007cc0 !important;
}

.emu-scr-splitter * {
	box-sizing: border-box;
}

.emu-scr-splitter {
	border: none;
}

.emu-scr-splitter--horizontal {
}

/*#endregion */

/*#region generic (base) styling */

/*
	Applies to all screen types and layouts
*/

.emu-scr-cmp-wrapper {
	width: 100%;
	padding-bottom: 15px;
	overflow-y: auto;
}

.emu-scr-cmp-wrapper.k-pane {
	// This lines exist only because kendo splitter's rule override's the `.emu-scr-cmp-wrapper`'s styling
	overflow-y: auto;
}

.emu-scr-cmp-wrapper, .emu-scr-cmp-wrapper * {
	box-sizing: border-box;
}

.emu-scr-cmp-wrapper__row {
	width: 100%;
	padding: 15px;
}

.emu-scr-cmp-wrapper__header {
	height: 18px;
}

.emu-scr-cmp-wrapper__group {
	width: 100%;
	margin-bottom: 13px;

	&:after {
		clear: both;
		content: "";
		display: block;
	}
}

.emu-scr-cmp-wrapper__group--empty {
	margin-top: 18px;
	margin-bottom: 0;
}

.emu-scr-cmp-wrapper__group--no-margin {
	margin-bottom: 0 !important;
}

.emu-scr-cmp-wrapper__separator {
	display: block;
	width: 100%;
	margin: 0;
	border: 0;
	padding: 0;
}

.emu-scr-cmp-wrapper__item {
	float: left;
	width: 100%;
	padding-top: 2px;
	padding-bottom: 2px;

	.emu-scr-cmp-wrapper__item {
		padding: 0;
	}
}

.emu-scr-cmp-wrapper__item--double {
}

.emu-scr-cmp-wrapper__item--double .emu-scr-cmp-wrapper__item:first-child {
	float: left;
	width: calc(50% - 15px);
	margin-right: 15px;
}

.emu-scr-cmp-wrapper__item--double .emu-scr-cmp-wrapper__item:last-child {
	float: left;
	width: 50%;
}

.emu-scr-cmp-wrapper__item--empty *:not(label) {
	font-style: italic;
	color: #ccc;
}

.emu-scr-cmp-wrapper__item--watermark {
	position: absolute;
	width: auto;
	float: none;
	bottom: 0;
	right: 0;
	z-index: 15;
	padding: 10px;
	font-style: italic;
	opacity: 0.5;
}

.emu-scr-icon-button {
	background-color: transparent;
	border: none;
	padding: 0px;
}

.emu-scr-icon-button:hover {
	cursor: pointer;
}

.emu-scr-icon-button:focus {
	outline-offset: 0px !important;
	outline: none !important;
	border: none !important;
}

.emu-scr-icon-button:active {
	outline-offset: 0px !important;
	outline: none !important;
	border: none !important;
}

.emu-scr-icon-button img {
	max-width: 24px;
	max-height: 24px;
}

/*#endregion */

/*#region main */

.emu-scr-cmp-wrapper--main .emu-scr-master__row {
	border: 1px solid #dadada;
	border-top-left-radius: 20px;
	padding: 15px 0 0 0;
	box-shadow: 0px 6px 11px -6px #aaa;
	-webkit-box-shadow: 0px 6px 11px -6px #aaa;
}

.emu-scr-cmp-wrapper--main .emu-scr-master__row--focus {
	width: 100%;
	border-right-color: #3598dc;
	border-right-width: 5px;
}

.emu-scr-cmp-wrapper--main .emu-scr-master__row--with-actions-panel {
	width: calc(100% - 30px);
	margin-right: 45px;
}

.emu-scr-cmp-wrapper--main .emu-scr-master__row--with-actions-panel--with-focus {
	width: calc(100% - 26px);
}

/*#endregion */

/*#region side-screen */

.emu-scr-cmp-wrapper--side {
	height: calc(100% - 20px); /* the height of side panel's title element */
}

/*#endregion */

/*#region reports */

.emu-scr-cmp-wrapper.emu-scr-cmp-wrapper--report {
	// This rule is to override the overflow-y: auto,
	// in custom report screens
	overflow-y: hidden;
}

/*#endregion */


/*#region combinations and special cases */
.emu-scr-cmp-wrapper--side.emu-scr-cmp-wrapper--utility {
	padding: 0;
}

.emu-scr-cmp-wrapper--modal.emu-scr-cmp-wrapper--tabular {
	padding: 0 15px 15px 15px;
}

.emu-scr-cmp-wrapper--side.emu-scr-cmp-wrapper--tabular {
	padding: 0 15px 15px 15px;
}

.emu-scr-cmp-wrapper--utility-parm.emu-scr-cmp-wrapper--main .emu-scr-master__row--focus {
	border-right-color: #dadada;
	border-right-width: 1px;
}

/*#endregion */
