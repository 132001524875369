﻿$border-color: #dadada;

@mixin cell-style {
	width: 150px;
	min-width: 150px;
	max-width: 150px;
	padding: 8px 20px !important; /* override bootstrap styling */
	vertical-align: middle !important; /* override bootstrap styling */
}

@mixin cell-index-style {
	width: 50px; /* DO NOT Change - Used in BaseObject -> GenerateTabularHTMLContent -> Transform.CellWidth, Transform.DetailToolbarWidth, generateColumnsWidth method */
	min-width: 50px; /* DO NOT Change - Used in BaseObject -> GenerateTabularHTMLContent -> Transform.CellWidth, Transform.DetailToolbarWidth, generateColumnsWidth method */
	padding: 8px !important; /* DO NOT Change */ /* override bootstrap styling */
}

.emu-scr-data-table {
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	// height: is calculated through javascript...
	&__row {
		position: relative;

		&--focus {
			background-color: #e6f3fe !important; /* important needed to override bootstrap's style */
		}

		&--highlight {
			background-color: #f5f5f5; /* default bootstrap's color code */
		}
	}

	&__inner-wrapper {
		height: 100%;
		overflow: hidden;
	}

	&__header-wrapper, &__footer-wrapper {
		position: relative;
	}

	&__main-wrapper {
		position: relative;
		overflow-y: auto;
	}

	&__header {
		position: relative;
		top: 1px; /* effect to make it appear stacked to data table */
		z-index: 10;
		width: auto !important; /* To override unknown 102% width */
		margin: 0;
		border-bottom: 2px solid #ddd; /* Bootstrap default border color */
		overflow: auto;
		table-layout: fixed;
	}

	&__main {
		position: relative;
		width: auto;
		margin: 0;
		table-layout: fixed;
	}

	&__footer {
		position: relative;
		top: -1px; /* effect to make it appear stacked to data table */
		z-index: 10;
		width: auto !important; /* To override unknown 102% width */
		margin: 0;
		border-top: 2px solid #ddd; /* Bootstrap default border color */
		overflow: auto;
		table-layout: fixed;
		font-weight: bold;
	}

	&__cell {
		@include cell-style;
		color: $black;
		text-align: center;

		&--index {
			@include cell-index-style;
		}
	}

	&__item {

		text-align: left;

		&--index {
			@include cell-index-style;
			text-align: center;
			font-weight: bold;
		}

		&--center {
			text-align: center;
		}

		&--right {
			text-align: right;
		}

		&--no-border-right {
			border-right: none !important;
		}

		&--no-border-left {
			border-left: none !important;
		}
	}
}

.emu-scr-data-table--report {
		
	.emu-scr-data-table__header {
		top: 0px;
		border-bottom: none;
	}

	.emu-scr-data-table__footer {
		top: 0px;
		border-top: none;
	}

	.emu-scr-data-table__cell {
			border: none;
			padding: 5px 10px!important;
			line-height: 16px;
			text-align: left;
		}

	.emu-scr-data-table__row--header {
		.emu-scr-data-table__cell {
			color: $white;
		}
	}

	.emu-scr-data-table__row--data {
		border-bottom: 1px solid $border-color;
	}

	.emu-scr-data-table__row--footer {
		background-color: $almost-white;
	}

	.cmp__input--readonly {
		background-color: transparent;
	}
}