﻿$emu-scr-buttons-background-color: #f7f7f7;
$emu-scr-buttons-border-color: #ccc;

.emu-scr-buttons {
	$self: &;

	&__strip {
	}

	&__button {
		font-size: 12px;
	}

	&__text {
	}

	&--side-panel {

		#{$self}__strip {
			display: block;
			padding: 5px;
			text-align: right;
			background: $emu-scr-buttons-background-color;
		}

		#{$self}__button {
			display: inline-block;
			margin: 1px 3px;
			border: 1px solid $emu-scr-buttons-border-color;
			border-radius: 2px;
			padding: 5px 15px;
			color: $base-branding-color-2;
			cursor: pointer;
			background: $emu-scr-buttons-background-color;
		}
	}
}
