﻿.emu-scr-detail {
	width: 100%;
	float: left;
	margin-bottom: 15px;
	padding: 0 15px;

	&__header {
		width: 100%;
		padding-top: 15px;
		overflow: hidden;

		&:empty {
			display: none;
		}
	}

	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		border-top: 1px solid #dadada;
		padding: 5px 15px;
		background: #eaeaea;
		overflow: hidden;

		&:empty {
			display: none;
		}
	}

	&__row-wrapper {
		overflow: hidden;
		float: left;
		clear: both;
	}

	&__row {
		position: relative;
		width: 100%;
		float: left;
	}

	&__row-inner-wrapper {
		position: relative;
		width: calc(100% - 10px);
		float: left;
		margin: 15px 10px 0 0;
		border: 1px solid #dadada;
		padding: 15px 0 0 3px; /* the `3` value of the left padding is used in combination with the --focus class below */
	}

	&__index {
		position: absolute;
		left: 0px;
		top: 15px;
		display: inline-block;
		width: 26px;
		height: auto;
		padding: 3px 0;
		text-align: center;
		background-color: #aaa;
	}
}

.emu-scr-detail__row--focus .emu-scr-detail__row-inner-wrapper {
	padding-left: 1px;
	border-left-width: 3px;
	border-left-color: #3591d2;
}

.emu-scr-detail__row--with-index .emu-scr-detail__row-inner-wrapper {
	width: calc(100% - 40px);
	margin-left: 30px;
	margin-right: 10px;
}

.emu-scr-detail__row--focus .emu-scr-detail__index {
	background-color: #888;
}
