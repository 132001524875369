﻿.emu-scr-search-toolbar-collapse-expand {
	position: relative;

	&__content {
		height: 89px; /* hardcoded for performance */
		&--collapsed {
			height: 0;
			opacity: 0;
		}
	}

	&__handler-group {
		position: absolute;
		bottom: -14px;
		left: 50%;
		z-index: 50;
		display: block;
		width: 80px;
		height: 15px;
		margin-left: -40px;
		border: 1px solid #e1e1e1;
		border-top: none;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		text-align: center;
		background-color: #f7f7f7;
		color: #000;
		cursor: pointer;
	}

	&__handler {
		display: block;
		height: 14px;
		line-height: 14px;
		font-size: 10px;
		text-transform: uppercase;
	}
}
