﻿.emu-scr-report-filters {
	height: 100%;
	width: 0px;
	position: absolute;
	z-index: 200;
	top: 0;
	right: 0;
	background-color: #fff;
	transition: 0.3s;
	border-left: 1px solid #11116c;

	&__flag {
		width: 40px;
		height: 40px;
		position: absolute;
		background-color: #11116c;
		color: white;
		font-size: 11px;
		letter-spacing: 0.2em;
		text-align: center;
		text-transform: uppercase;
		left: -40px;
		top: -1px;
		cursor: pointer;
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 58%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 58%);

		&:after {
			content: "";
			position: absolute;
			left: -1px;
			bottom: -1px;
			width: 0;
			height: 0;
			border-bottom: 18px solid #fff;
			border-right: 40px solid transparent;
		}
	}

	.red-flag {
		background: #cc0000 !important;
	}

	&__flag-icon {
		position: absolute;
		top: 2px;
		right: 2px;
		width: 25px;
		height: 25px;
	}

	&__title-wrap {
		margin-top: 10px;
		width: 100%;
		overflow: hidden;
		outline: 0px;
		text-align: center;
	}

	&__title {
		text-align: left;
		margin-left: 20px;

		span {
			color: #00205b;
			font-weight: bold;
		}
	}

	&__buttons {
		margin-top: 10px;
		width: 100%;
		overflow: hidden;
		outline: 0px;
		text-align: center;
		position: absolute;
		bottom: 5px;
	}

	&__apply-button {
		margin-bottom: 5px;
		width: 50%;
	}

	&__save-button {
		margin-bottom: 5px;
		width: 20%;
	}

	&__reset-button {
		margin-bottom: 5px;
		width: 20%;
	}

	.emu-scr-cmp-wrapper .emu-scr-master {
		height: calc(100% - 70px);
	}

	.emu-scr-cmp-wrapper .emu-scr-master__row {
		padding: 0;
		border: none;
		box-shadow: none;
		-webkit-box-shadow: none;
	}
}
