﻿@import '../settings/typography.scss';

/* ---- structure variables ---- */
$spacing: 5px;
$border-color: #dadada;

/* ---- color variables ---- */
$theme-color-text: #000000;
$theme-color-primary: #29487d; /* DANAOS dark blue */
$theme-color-secondary: red;
$theme-color-tertiary: #3591d2; /* DANAOS base color */

/**
 * Remove negetive margins from the `row` bootstrap element,
 * and also make it full width
 */
%boostrap-row-fix {
	width: 100%;
	margin: 0;
}


/* ---- screen parts (layout) ---- */
.emu-scr-report {
}

/* ---- header elements ---- */
.emu-scr-report__header {
	width: 100%;
	border-bottom: 1px solid $border-color;
	padding-bottom: ($spacing * 3);
}

.emu-scr-report__title {
	display: block;
	text-align: center;
	font-size: $xl-font-size;
	color: $theme-color-primary;
}

.emu-scr-report__subtitle {
	display: block;
	text-align: center;
	font-style: italic;
	font-size: $s-font-size;
	color: $grey;
}

.emu-scr-report__breadcrumb {
	position: absolute;
	z-index: 100;
	display: block;
	font-size: $s-font-size;
	color: $grey;
}

.emu-scr-breadcrumb__separator {
	padding: 0 3px;
}

/* ---- main elements ---- */
.emu-scr-report__main {
	width: 100%;
	padding-top: ($spacing * 2);
}

/* ---- footer elements ---- */

.emu-scr-report__footer {
	/* Inherits from main footer styles (master.scss) */
}

.emu-scr-report__timestamp {
	font-family: monospace;
	font-size: $s-font-size;
}

/* ---- re-usable blocks ---- */

.emu-scr-report__label {
	font-weight: bold;
	color: $black;
}

.emu-scr-report__text {
	font-weight: normal;
	color: $theme-color-text;
}

.emu-scr-report__text--\monospace {
	font-family: monospace;
}

.emu-scr-report__text--primary {
	color: $theme-color-primary;
}

.emu-scr-report__text--secondary {
	color: $theme-color-secondary;
}

.emu-scr-report__text--tertiary {
	color: $theme-color-tertiary;
}

.emu-scr-report__link {
	color: $theme-color-primary;
	text-decoration: underline;
	cursor: pointer;
	transition: color 0.15s ease-in-out;

	&:hover {
		color: lighten($theme-color-primary, 10%);
	}

	&:active {
		color: lighten($theme-color-primary, 20%);
	}
}

/* ---- row handling ---- */
.emu-scr-report__row {
	position: relative;
	min-height: ($m-font-size + 2 + ($spacing * 2));
	line-height: ($m-font-size + 2);
	padding: $spacing;

	> * {
		@include ellipsis;
	}
}

.emu-scr-report__row--content {
	padding: $spacing;
}

.emu-scr-report__row--data {
	@extend %boostrap-row-fix;
	padding: $spacing;
	font-weight: normal;
}

.emu-scr-report__row--header, .emu-scr-report__row--footer {
	@extend %boostrap-row-fix;
	font-weight: bold;
	background-color: $almost-white;
}

.emu-scr-report__row--header {
	border-bottom: 1px solid $border-color
}

.emu-scr-report__row--footer {
	border-top: 1px solid $border-color;
}

.emu-scr-report__row--no-style {
	padding: 0;

	> .emu-scr-report__data-table {
		padding: 0;
	}
}
// .emu-scr-report__row--data {
// 	.emu-scr-report__row--footer {
// 		padding-left: (($spacing * 2) + ($spacing * 2));
// 		padding-right: (($spacing * 2) + ($spacing * 2));
// 	}
// 	.emu-scr-report__row--data {
// 		.emu-scr-report__row--footer {
// 			padding-left: ($spacing * 2);
// 			padding-right: ($spacing * 2);
// 		}
// 		.emu-scr-report__row--data {
// 			.emu-scr-report__row--footer {
// 				// padding-left: 0;
// 				// padding-right: 0;
// 			}
// 		}
// 	}
// }
.emu-scr-report__row--fix-padding {
	padding-left: ($spacing * 2 + 2); /* +2 is the total pixels of border */
	padding-right: ($spacing * 2 + 2); /* +2 is the total pixels of border */
}

$sizes: 1,2,3,4,5,6,7,8,9,10; /* levels of nesting */
@each $size in $sizes {
	.emu-scr-report__row--fix-padding-#{$size} {
		padding-left: ($size * $spacing * 2) + 6 + $size - 1; /* + 6 is some kind of magic */
		padding-right: ($size * $spacing * 2) + 6 + $size - 1; /* + 6 is some kind of magic */
	}
}

/* ---- group helper elements ---- */
.emu-scr-report__group {
	border: 1px solid $border-color;
}

.emu-scr-report__group--no-style {
	border: none;
}

.emu-scr-report__group--focus {
	&.emu-scr-report__row {
		background-color: $theme-color-highlight !important;
	}

	.emu-scr-report__row {
		background-color: $theme-color-highlight !important;
	}
}

.emu-scr-report__data-table {
	padding: $spacing;
}


.emu-scr-report__data-table--striped {
	.emu-scr-report__row--data:nth-child(odd) {
		background-color: $theme-color-strip;
	}
}

.emu-scr-report__data-table .emu-scr-report__row--data, .emu-scr-report__data-table .emu-scr-report__row--footer {
	border: 1px solid $border-color;
	border-top: none;
}

.emu-scr-report__data-table .emu-scr-report__row--header {
	border: none;
}

.emu-scr-report__data-table .emu-scr-report__row--header {
	color: $white;
}


.emu-scr-report__group .emu-scr-report__row--header,
.emu-scr-report__group .emu-scr-report__row--footer,
.emu-scr-report__data-table .emu-scr-report__row--header,
.emu-scr-report__data-table .emu-scr-report__row--data,
.emu-scr-report__data-table .emu-scr-report__row--footer {
	display: flex;
	align-items: center;
}

.emu-scr-report__group .emu-scr-report__row--header > div,
.emu-scr-report__group .emu-scr-report__row--footer > div,
.emu-scr-report__data-table .emu-scr-report__row--header > div,
.emu-scr-report__data-table .emu-scr-report__row--data > div,
.emu-scr-report__data-table .emu-scr-report__row--footer > div {
	margin: 0 5px;
	min-height: 1px; // to prevent content shifting when no content is present
}

.emu-scr-report__data-table .emu-scr-report__sortable {
	cursor: pointer;
}
.emu-scr-report__data-table[data-drag] .emu-scr-report__row--header > div {
	cursor: pointer;
}

