﻿.emu-scr-cmp-wrapper--modal {
	overflow-y: auto;
	height: calc(100% - 42px); // 42px is the height of the action buttons on the footer of the modal
	padding: 0;
}

.emu-scr-cmp-wrapper--modal .emu-scr-master__row {
	padding: 0;
	border: none;
	box-shadow: none;
	-webkit-box-shadow: none;
}

.emu-scr-modal, .emu-scr-modal * {
	box-sizing: border-box !important;
}

.emu-scr-modal > .k-window-titlebar.k-header > .k-window-title {
	float: left;
	margin: .1em 0;
	line-height: 1em;
	white-space: nowrap;
	width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #ffffff !important;
}

.emu-scr-modal > .k-window-titlebar.k-header {
	height: 32px;
	font-size: 13px;
	font-family: Helvetica;
	background-color: whiteSmoke;
	border-radius: 1px;
	font-weight: 700;
	border-bottom-color: lightgray;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	box-sizing: border-box;
	color: gray !important;
}

.emu-scr-modal > .k-window-titlebar.k-header .k-window-actions .k-window-action {
	width: 22px;
	height: 22px;
	border: 1.5px solid #ccc !important;
	background: #f6f6f6 !important;
	font-weight: normal !important;
	box-sizing: border-box;
}

.emu-scr-modal > .k-window-titlebar.k-header .k-window-actions .k-window-action .k-icon {
	font-weight: bold !important;
	color: #333333 !important;
}

.emu-scr-modal .ui-dialog-buttonpane {
	float: left;
}
