@import 'settings/colors.scss';
@import 'settings/typography.scss';

@import 'tools/mixins.scss';

@import 'layout/screen.scss';
@import 'layout/master.scss';
@import 'layout/detail.scss';
@import 'layout/freeform.scss';
@import 'layout/tabular.scss';
@import 'layout/report.scss';
@import 'layout/report-filters.scss';
@import 'layout/report-parameters.scss';
@import 'layout/modal.scss';

@import 'modules/badge.scss';
@import 'modules/buttons.scss';

@import 'components/actions-panel.scss';
@import 'components/data-table.scss';
@import 'components/kendo-grid.scss';
@import 'components/kendo-tree.scss';
@import 'components/search-bar.scss';
@import 'components/search-toolbar-collapse-expand.scss';
@import 'components/sidebar.scss';
@import 'components/toolbar.scss';
@import 'components/graph.scss';
