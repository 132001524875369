﻿$state-warning-foreground-color: #525252;

.emu-scr-badge {
	display: inline-block;
	height: 16px;
	border-radius: 4px;
	padding: 2px 8px;

	line-height: 12px;
	letter-spacing: 0.05em;

	color: #fff;
	background-color: #999;

	&--state-info { background-color: $state-info-color; }

	&--state-success { background-color: $state-success-color; }

	&--state-error { background-color: $state-error-color; }

	&--state-warning { background-color: $state-warning-color; color: $state-warning-foreground-color; }

	&--hollow {
		height: 18px;
		border: 1px solid $dark-grey;
		background: transparent;
		color: $dark-grey;
		font-size: 11px;
	}
}
