﻿.emu-scr-kendo-tree { }

.emu-scr-kendo-tree .k-grid-norecords {
	position: absolute;
	width: 100%;
	height: 30px;
	line-height: 30px;
	top: 50%;
	margin-top: -15px;
	font-size: 20px;
	opacity: 0.6;
}
